"use client";
import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import Image from "next/image";
import { useRef } from "react";
import CandleCanvas from "../../../ui/CandleCanvas";

const CylinderBox = ({ children }) => {
    const boxRef = useRef(null);
    useGSAP(
        () => {
            const boxTimeline = gsap.timeline({
                scrollTrigger: {
                    trigger: boxRef.current,
                    start: "top 50%",
                    end: "top 50%",
                    toggleActions: "play none none reverse",
                    onEnter: () => {
                        gsap.to(".candle-wave", {
                            top: -300,
                            opacity: 1,
                            duration: 1
                        });
                    },
                    onLeaveBack: () => {
                        gsap.to(".candle-wave", {
                            top: -300,
                            opacity: 0,
                            duration: 0.5
                        });
                    }
                }
            });
            boxTimeline
                .to(".main-box", {
                    y: 0,
                    scale: 1,
                    opacity: 1,
                    duration: 1,
                    delay: 0.5
                })
                .to(".cylinder-blue", {
                    opacity: 1,
                    y: 0,
                    duration: 1,
                    delay: -0.7,
                    ease: "back.out(1.7)"
                })
                .to(".cylinder-purple", {
                    opacity: 1,
                    y: 0,
                    duration: 1,
                    delay: -0.7,
                    ease: "back.out(1.7)"
                })
                .to(".rectangle-blue", {
                    opacity: 1,
                    y: 0,
                    duration: 1,
                    delay: -0.7,
                    ease: "back.out(1.7)"
                });
            gsap.to(".floating-coin", {
                y: -10,
                duration: 1,
                repeat: -1,
                delay: -0.5,
                yoyo: true
            });
            gsap.to(".floating-hand", {
                y: -10,
                duration: 1,
                repeat: -1,
                delay: -0.8,
                yoyo: true
            });
        },
        { scope: boxRef }
    );
    return (
        <div
            className="mx-auto flex h-full w-full items-start pb-[80px] lg:pb-[180px]"
            ref={boxRef}
        >
            <div className="relative hidden w-1/2 pt-52 tab:block">
                <div className="relative mx-auto w-[500px]">
                    <div className="main-box-wrapper flex justify-center">
                        <Image
                            src="https://fundednext.fra1.cdn.digitaloceanspaces.com/lander-v2/box.png"
                            alt="box"
                            width={390}
                            height={440}
                            sizes="100vw"
                            className="main-box translate-y-[200px] scale-150 opacity-0"
                        />
                    </div>
                    <div className="candle-wave absolute left-[50%] top-[-150px] translate-x-[-50%] opacity-0">
                        <div className="absolute left-0 top-0 h-full w-[40px] bg-gradient-to-r from-black"></div>
                        <CandleCanvas />
                        <div className="absolute right-0 top-0 h-full w-[40px] bg-gradient-to-l from-black"></div>
                    </div>
                    <div className="cylinder-blue absolute left-[40px] top-[-90px] h-[260px] w-[87px] translate-y-[100px] opacity-0">
                        <Image
                            src="https://fundednext.fra1.cdn.digitaloceanspaces.com/lander-v2/payout.png"
                            alt="Gurranted Payout"
                            height={58}
                            width={58}
                            className="absolute left-5 top-[45px] z-[1]"
                        />
                        <Image
                            src="https://fundednext.fra1.cdn.digitaloceanspaces.com/lander-v2/blue-union.png"
                            alt="Cylinder light"
                            height={103}
                            width={70}
                            className="mx-auto translate-x-[-1px] translate-y-[15px]"
                        />
                        <Image
                            src="https://fundednext.fra1.cdn.digitaloceanspaces.com/lander-v2/cylinder-blue.png"
                            height={260}
                            width={87}
                            sizes="100vw"
                            alt="cylinder blue"
                        />
                    </div>
                    <div className="cylinder-purple absolute left-[100px] top-[-15px] translate-y-[100px] opacity-0">
                        <Image
                            src="https://fundednext.fra1.cdn.digitaloceanspaces.com/lander-v2/floating-coin.png"
                            alt="Floating Coin"
                            height={70}
                            width={65}
                            className="floating-coin absolute left-4 top-[40px] z-[1]"
                        />
                        <Image
                            src="https://fundednext.fra1.cdn.digitaloceanspaces.com/lander-v2/purple-union.png"
                            alt="Cylinder light"
                            height={112}
                            width={77}
                            className="mx-auto translate-x-[0px] translate-y-[18px]"
                        />
                        <Image
                            src="https://fundednext.fra1.cdn.digitaloceanspaces.com/lander-v2/cylinder-purple.png"
                            width={95}
                            height={265}
                            sizes="100vw"
                            alt="cylinder purple"
                        />
                    </div>
                    <div className="rectangle-blue absolute right-[60px] top-[-50px] translate-y-[100px] opacity-0">
                        <Image
                            src="https://fundednext.fra1.cdn.digitaloceanspaces.com/lander-v2/floating-hand.png"
                            alt="Floating Hand"
                            height={80}
                            width={65}
                            className="floating-hand absolute left-5 top-[20px] z-[1]"
                        />
                        <Image
                            src="https://fundednext.fra1.cdn.digitaloceanspaces.com/lander-v2/box-union.png"
                            alt="Cylinder Light"
                            height={145}
                            width={96}
                            className="mx-auto translate-x-[0px] translate-y-[18px]"
                        />
                        <Image
                            src="https://fundednext.fra1.cdn.digitaloceanspaces.com/lander-v2/box-blue.png"
                            width={103}
                            height={217}
                            alt="rectangle blue"
                        />
                    </div>
                </div>
            </div>
            {children}
        </div>
    );
};

export default CylinderBox;
