"use client";
import { useEffect, useRef } from "react";
import WaveNoise from "../utils/WaveNoise";
const CandleCanvas = () => {
    const canvasRef = useRef(null);
    useEffect(() => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext("2d");

        canvas.width = 325;
        canvas.height = 300;

        const waveNoise = new WaveNoise();
        class Candle {
            constructor(x, h) {
                this.x = x;
                this.w = 48;
                this.h = h;
                this.xSpeed = 0.5;
                this.y = canvas.height / 2 - this.h / 2;
                this.variation = 0.006;
                this.amp = 100;
                this.variators = 0;
            }

            update() {
                // Speed is negative because it's moving right to left where x is decreasing
                this.x -= this.xSpeed;
                const y = waveNoise.noise(
                    this.x * this.variation + this.variators,
                    this.x * this.variation,
                    0
                );
                this.y = canvas.height / 2 - this.h / 2 + this.amp * y;
                this.variators += 0.005;

                // Reset x position when it reaches left edge
                if (this.x < -this.w) {
                    this.x = canvas.width;
                }

                return {
                    x: this.x,
                    y: this.y,
                    w: this.w,
                    h: this.h
                };
            }
        }

        const getRandomValue = (min, max) => min + (max - min) * Math.random();
        const BlueImageSrc = "./blue-candle.png";
        const blueImage = new Image();
        blueImage.src = BlueImageSrc;

        const PurpleImageSrc = "./purple-candle.png";
        const purpleImage = new Image();
        purpleImage.src = PurpleImageSrc;

        const candleCount = 7;
        const candles = [];
        for (let i = 0; i < candleCount; i++) {
            candles.push(new Candle(i * 53.5, getRandomValue(80, 300)));
        }
        const animate = () => {
            requestAnimationFrame(animate);
            // Get updated candle positions
            const updatedPositions = candles.map((candle) => candle.update());
            // Clear the canvas
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            // Draw the candles at updated positions
            for (const [index, position] of updatedPositions.entries()) {
                ctx.drawImage(
                    index === 2 || index === 6 ? purpleImage : blueImage,
                    position.x,
                    position.y,
                    position.w,
                    position.h
                );
            }
        };
        animate();
    }, []);
    return <canvas ref={canvasRef}></canvas>;
};

export default CandleCanvas;
